<template>
  <div id="cooperativeAdd">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/cooperativeResources' }">合作资源</el-breadcrumb-item>
			<el-breadcrumb-item>添加合作资源</el-breadcrumb-item>
		</el-breadcrumb>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="cooperativeAdd-from"
      style="box-sizing: border-box"
      >
      <el-row>
        <el-col :span="10">
          <el-form-item label="机构名称/品牌名称" prop="name">
            <el-input v-model="ruleForm.name" type="text" placeholder="请输入机构名称" @blur="searchOrgans"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="机构全称">
            <el-input v-model="ruleForm.company_full_name" type="text" placeholder="请输入机构全称" :disabled="is_exit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="image" label-width="80px">
        <SuperUpload
          :uploadCardAddress="uploadLogoAddress"
          :imgLimit="20"
          :cardplanList="imgList"
          @success="imgUploadSuccess"
          @CardPlanRemove="imgRemove"
          @handleExceed="handleExceed"
          :isDisabled="is_exit"
          :size="4194320"
          width="100%"
          height="100%"
        ></SuperUpload>
        <p class="tips">最多上传20张图片</p>
        <p class="tips">建议上传尺寸为1.5：1的图片</p>
        <p class="tips">仅支持jpg、jpeg、png格式大小不能大于4M</p>
      </el-form-item>
      <el-row>
        <el-col :span="10">
          <el-form-item label="行业领域:" class="organization_industry">
            <el-select
              v-model="ruleForm.start_id"
              multiple
              collapse-tags
							style="width: 100%"
              placeholder="请选择">
              <el-option
                v-for="item in options_start"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="房型" prop="houseTypes">
            <el-select multiple v-model="ruleForm.house_type_id" placeholder="请选择" style="width: 100%;" :disabled="is_exit">
              <el-option
                v-for="item in houseTypes"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="属性标签">
            <el-cascader
              v-model="ruleForm.attribute_id"
              :options="attributes"
               :disabled="is_exit"
              :props="{ multiple: true, checkStrictly: true ,value:'id',label:'title'}"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="2">
          <div class="position" style="display: flex;align-items: center;">
            <el-form-item label="地理位置" prop="province_id">
              <el-select
                @change="provinceChange"
                v-model="ruleForm.province_id"
                clearable
                placeholder="省"
                style="width:120px;margin-right: 10px;"
                :disabled="is_exit"
              >
                <el-option
                  v-for="item in provinces"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="city_id" label-width="0">
              <el-select
                @change="cityChange"
                v-model="ruleForm.city_id"
                placeholder="市"
                clearable
                style="width:120px;margin-right: 10px;"
                :disabled="is_exit"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="district_id" label-width="0">
              <el-select
                v-model="ruleForm.district_id"
                placeholder="区"
                clearable
                style="width:120px;margin-right: 8px;"
                :disabled="is_exit"
              >
                <el-option
                  v-for="item in districts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item label="详细地址" prop="address">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入具体位置"
              v-model="ruleForm.address"
              maxlength="50"
              show-word-limit
              :disabled="is_exit"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item class="investSubmit">
        <el-button  type="primary" @click="submitForm('ruleForm')" size="medium" class="searchBtn">发布</el-button>
        <el-button  type="primary" @click="$router.push('/cooperativeResources')" size="medium" class="searchBtn">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import SuperUpload from "@/components/Upload/SuperUpload.vue";
import { success, error, warning } from "@/utils/notification";

export default {
  name: 'cooperativeAdd',
  data() {
    return {
      id: Number(sessionStorage.organizationId),
      ruleForm: {
        images: [], //图片
        name: "", //机构名称
        company_full_name: "", //公司全称
        province_id: [], //省份
        city_id: [], //城市
        district_id: [], //区域
        address: "", //地区
        attribute_id: [], //属性
        house_type_id: [], //房型
				economic_id: [],	//国民经济行业领域编号
				start_id: []	//创投行业领域编号
      },
      is_exit: false,
			options_economic: [], //级联分类领域-国民经济
			options_start: [], //级联分类领域-创投
      provinces: [], //省
      cities: [], //市
      districts: [], //区
      uploadLogoAddress: this.URL.upLoad,
      imgList: [],
      options: [], //级联分类领域
      houseTypes: [], //房源类型
      attributes: [], //属性标签
      isObtain: false,
      rules: {
        //表单验证规则
        name: [
          { required: true, message: "机构名称不能为空", trigger: "blur" },
          { min: 1, max: 20, message: "长度 1 到 20 个字符", trigger: "blur" }
        ],
        province_id: [
          { required: true, message: "请选择省份", trigger: "change" }
        ],
        city_id: [{ required: true, message: "请选择城市", trigger: "change" }],
        district_id: [
          { required: true, message: "请选择区域", trigger: "change" }
        ],
        address: [
          { required: true, message: "地址不能为空", trigger: "blur" },
          { min: 1, max: 50, message: "长度 1 到50 个字符", trigger: "change" }
        ]
      }
    }
  },
  components: {
    SuperUpload
  },
  methods: {
    // 图片上传成功
    imgUploadSuccess(response, filelist, file) {
      success("图片上传成功");
      if (filelist.length > 0) {
        filelist.map(v => {
          if (v.uid === file.uid) {
            v.path = response.data.path;
          }
        });
      }
      this.imgList = filelist;
    },
    // 图片移除
    imgRemove(filelist) {
      this.imgList = filelist;
    },
    // 图片提示
    handleExceed() {
      error("上传图片不能超过20张");
    },
    // 根据省选择城市
    publicArea(value) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: value
          }
        })
        .then(res => {
          if (value == 3246) {
            this.provinces = res.data.data;
          }
          // else {
          //   this.cities = res.data.data;
          // }
        });
    },
    // 城市数据
    provinceChange(value) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: value
          }
        })
        .then(res => {
          this.cities = res.data.data;
        });
      this.ruleForm.city_id = "";
      this.ruleForm.district_id = "";
      this.districts = [];
    },
    // 地区数据
    cityChange(value) {
      if (value != "") {
        this.$http
          .get(this.URL.getArea, {
            params: {
              city_id: value
            }
          })
          .then(res => {
            this.districts = res.data.data;
          });
        this.ruleForm.district_id = "";
      }
    },
    // 获取属性标签
    getAttribute() {
      this.$http.get(this.URL.getAttribute, {}).then(res => {
        this.attributes = res.data.data;
        // if (this.id && this.ruleForm.industry_id.length > 0) {
        //   this.attributes = this.attributes.map(item => item.id);
        //   console.log(this.attributes,'this.attributes')
        //   this.ruleForm.industry_id = this.ruleForm.industry_id.map(item => {
        //     this.attributes.includes(item)
        //       ? (item = [item])
        //       : (item = [7, item]);
        //     return item;
        //   });
        // }
      });
    },
    // 浮点型 统一处理方法
    isNumber(val, data, text) {
      let show = validata.checkNumber(val);
      if (!show) {
        warning("请输入正确的" + text);
        this.ruleForm[data] = "";
      }
    },
    // 获取房源类型
    getHouse() {
      this.$http.get(this.URL.houseTypes, {}).then(res => {
        this.houseTypes = res.data.data;
      });
    },
    // 分类 - 创投和国民行业列表
    async getCategories(url) {
      const res = await this.$http.get(this.URL.adminUrl[url], {});
			url == "economics"?this.options_economic = res.data.data:this.options_start = res.data.data
    },
    // 机构是否存在
    searchOrgans() {
      let newName = this.ruleForm.name
      this.$http.get(this.URL.adminUrl.searchOrgans,{params:{
        organ_name: this.ruleForm.name
      }}).then(res=>{
        if(res.data.data.is_exit){
          this.imgList = []
          this.is_exit = res.data.data.is_exit
          this.provinceChange(res.data.data.organ.province.id)
          this.cityChange(res.data.data.organ.city.id)
          this.ruleForm = res.data.data.organ
          this.ruleForm.attribute_id = res.data.data.organ.attribute_labels;
          let arr = this.attributes.map(item => item.id);
          this.ruleForm.attribute_id = this.ruleForm.attribute_id.map(item => {
            if (arr.includes(item)) {
              item = [item];
            } else {
              item = [7, item];
            }
            return item;
          });
          this.ruleForm.house_type_id = res.data.data.organ.house;
          let images = res.data.data.organ.images;
          if (images != null) {
            images.map(v => {
              let obj = {};
              obj.uid = "";
              obj.url = v;
              obj.path = v;
              this.imgList.push(obj);
            });
          }
          this.isObtain = true
        }else{
          if(this.isObtain){
            this.is_exit = false
            this.ruleForm = {
              name: newName, //机构名称
              images: [], //图片
              company_full_name: "", //公司全称
              province_id: "", //省份
              city_id: "", //城市
              district_id: "", //区域
              address: "", //地区
              attribute_id: [], //属性
              house_type_id: [], //房型
              economic_id: [],	//国民经济行业领域编号
              start_id: []	//创投行业领域编号
            }
            this.cities = [],
            this.districts = [],
            this.imgList = []
            this.isObtain = false
          }
        }
      })
    },
    // 提交
    submitForm(ruleForm) {
      let arr = []; //属性
      this.ruleForm.attribute_id.length > 0 &&
        this.ruleForm.attribute_id.forEach(item => {
          item.length > 1 && item.splice(0, 1);
          arr.push(...item);
        });
      this.ruleForm.attribute_id = arr;
      let imgList = this.imgList; //图片
      if (imgList.length === 0) {
        error("至少上传一张图片");
        return false;
      }
      imgList.map(v => {
        this.ruleForm.images.push(v.path);
      });
      //表单总验证
      if(this.is_exit){ //该机构存在
        let obj = {
         cooperation_organ_id: this.ruleForm.id
        }
        this.$http
          .post(this.URL.adminUrl.cooperationAdd + this.id, obj)
          .then(res => {
            success("添加成功")
            this.$router.push('/cooperativeResources')
          });
      }else{
        this.ruleForm.cooperation_organ_id = 0,
        this.$refs[ruleForm].validate(valid => {
          if (valid) {
            this.$http
              .post(this.URL.adminUrl.cooperationAdd + this.id, this.ruleForm)
              .then(res => {
                success("添加成功")
                this.$router.push('/cooperativeResources')
              });
          } else {
            error("请检查必填项是否填写完整");
            return false;
          }
        });
      }
    },
  },
  created() {
    this.getCategories("economics");
    this.getCategories("starts");
    this.getAttribute(); //获取属性标签
    this.publicArea(3246); //获取省市
    this.getHouse(); //获取房源类型
  }
}
</script>
<style lang="less" scoped>
  .tips {
    font-size: 12px;
    color: #ed1324;
    height: 24px;
  }
  .organization_industry{
    .el-radio-group,.el-cascader{
      width: 100%;
    }
  }
  .cooperativeAdd-from{
    margin-top: 30px;
  }
</style>
